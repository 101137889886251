import React from 'react'
import Header from '../components/landing/header'

export default function Privacy () {
  return (
    <div className='bg-gradient-to-b from-[#060525] via-[#02096E] via-[#030756]  via-[#130412]  via-[#050534] via-[#06041F] to-[#060317] overflow-hidden min-h-screen'>
      <Header />
      <div className='container mx-auto text-white container px-5 mt-10'>
        <h1 className='text-3xl mb-2'>Terms of Service</h1>
        <p className='mb-10'>
          Last Updated: 02<sup>nd</sup> December 2022
        </p>

        <p className='mb-6'>
          1. General. Liyana AI (“Liyana”) is an AI writing assistant powered by
          GPT-3. By using the Liyana AI service (the “Service”), you agree to be
          bound by these terms and conditions (the “Terms”).
        </p>
        <p className='mb-6'>
          2. Liyana AI Content. The Service provides AI-generated content (the
          “Content”) that can be used to help you write your own content. The
          Content is not intended to be used as a standalone or complete work.
          You acknowledge that the Content is provided “as is” and that Liyana
          AI makes no representations or warranties regarding the accuracy,
          completeness, or usefulness of the Content.
        </p>
        <p className='mb-6'>
          3. Responsibility for Content. You are solely responsible for all
          Content that you generate or store on the Service. You must ensure
          that all Content you generate or store on the Service does not
          infringe the intellectual property rights of any third party, or any
          other applicable laws or regulations. You agree to indemnify, defend
          and hold Liyana AI and its affiliates harmless from and against any
          and all claims, damages, losses, costs, expenses, or other liabilities
          arising from or related to your Content.
        </p>
        <p className='mb-6'>
          4. User Content. You may submit content and other materials (“User
          Content”) to the Service. You retain all rights to your User Content
          and you are solely responsible for your User Content. You represent
          and warrant that you have all the rights, power, and authority
          necessary to grant the rights granted herein to any User Content that
          you submit.
        </p>
        <p className='mb-6'>
          5. Liyana AI Proprietary Rights. The Service and all Content generated
          or provided through the Service is the exclusive property of Liyana
          AI, and/or its licensors, and is protected by copyrights, trademarks,
          service marks, patents, trade secrets, and other proprietary rights
          and laws.
        </p>
        <p className='mb-6'>
          6. Liability. Liyana AI shall not be liable for any direct, indirect,
          incidental, special, consequential or exemplary damages, including but
          not limited to, damages for loss of profits, goodwill, use, data or
          other intangible losses, resulting from: (I) the use or the inability
          to use the Service; (ii) the cost of procurement of substitute goods
          and services resulting from any goods, data, information or services
          purchased or obtained or messages received or transactions entered
          into through or from the Service; (iii) unauthorized access to or
          alteration of your transmissions or data; (iv) statements or conduct
          of any third party on the Service; or (v) any other matter relating to
          the Service.
        </p>
        <p className='mb-6'>
          7. Termination. Liyana AI may terminate your use of the Service at any
          time for any reason.
        </p>
        <p className='mb-6'>
          8. Changes to Terms. Liyana AI reserves the right to modify the Terms
          at any time. Any changes to the Terms will be posted on the Service
          and the date of the most recent revisions will be posted at the bottom
          of these Terms. By continuing to use the Service after any changes are
          posted, you are agreeing to be bound by the Terms as modified.
        </p>
        <p className='mb-6'>
          9. Miscellaneous. These Terms constitute the entire agreement between
          you and Liyana AI and govern your use of the Service, superseding any
          prior agreements between you and Liyana AI. The failure of Liyana AI
          to exercise or enforce any right or provision of these Terms shall not
          constitute a waiver of such right or provision. If any provision of
          these Terms is found by a court of competent jurisdiction to be
          invalid, the parties nevertheless agree that the court should endeavor
          to give effect to the parties' intentions as reflected in the
          provision, and the other provisions of these Terms remain in full
          force and effect. You agree that regardless of any statute or law to
          the contrary, any claim or cause of action arising out of or related
          to use of the Service or these Terms must be filed within one (1) year
          after such claim or cause of action arose or be forever barred. The
          section titles in these Terms are for convenience only and have no
          legal or contractual effect.
        </p>

        <p className='mb-6'>
          If you have any questions or concerns about the Terms of Service,
          please contact us at info@liyana.ai
        </p>
      </div>
    </div>
  )
}
